import { graphql, useStaticQuery } from 'gatsby'
import { useLocation } from '@reach/router'
import React from 'react'
import Banner from '../../components/Banner'
import { useFormContext } from '../../context/form.context'

const bannerContent = [
  {
    text: {
      pl: '+48 58 772 39 00',
      en: '+48 58 772 39 00',
      ua: '+48 58 772 39 00',
    },
    subtitle: {
      pl: 'Zapytaj o ofertę',
      en: 'Ask for an offer',
      ua: 'ЗАПИТАЙТЕ ПРОПОЗИЦІЮ',
    },
    link: 'tel: +48 58 772 39 00',
  },
  {
    text: {
      pl: 'oferta@seaandair.pl',
      en: 'oferta@seaandair.pl',
      ua: 'oferta@seaandair.pl',
    },
    subtitle: {
      pl: 'Napisz wiadomość',
      en: 'Send a message',
      ua: 'Написати повідомлення',
    },
  },
]

const bannerUkraine = [
  {
    text: {
      pl: '+48 58 772 39 00',
      en: '+48 58 772 39 00',
      ua: '+48 58 772 39 00',
    },
    subtitle: {
      pl: 'Zapytaj o ofertę',
      en: 'Ask for an offer',
      ua: 'ЗАПИТАЙТЕ ПРОПОЗИЦІЮ',
    },
    link: 'tel: +48 58 772 39 00',
  },
  {
    text: {
      pl: 'ukraine@seaandair.pl',
      en: 'ukraine@seaandair.pl',
      ua: 'ukraine@seaandair.pl',
    },
    subtitle: {
      pl: 'Napisz wiadomość',
      en: 'Send a message',
      ua: 'Написати повідомлення',
    },
  },
]

const label = {
  text: {
    pl: 'Szybki kontakt',
    en: 'Quick contact',
    ua: 'Швидкий контакт',
  },
  color: '#036FAE',
}

const BannerContact = () => {
  const location = useLocation()
  const { setFormType, setFormOpen } = useFormContext()

  const { image } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/banners/service.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
    }
  `)

  return (
    <Banner
      data={
        location?.pathname?.includes('transport-poland-ukraine')
          ? bannerUkraine
          : bannerContent
      }
      label={label}
      action={() => {
        setFormType('MESSAGE')
        setFormOpen(true)
      }}
      image={image}
    />
  )
}

export default BannerContact
