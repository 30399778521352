import React from 'react'
import FormDynamic from '../../components/FormDynamic'
import Main from '../../components/Main'
import { useLangContext } from '../../context/lang.context'

const content = {
  title: {
    pl: 'Wycena transportu. <br/>Postaw na zaufaną firmę TSL!',
    en: 'Transport quote. <br/>Choose a trusted TSL company!',
    ua: 'Транспортна пропозиція. <br/>Зробіть ставку на перевірену компанію TSL!',
  },
  subtitle: {
    pl: 'Formularz kontaktowy',
    en: 'Contact form',
    ua: 'КОНТАКТНИЙ ФОРМУЛЯР',
  },
  desc: {
    pl: 'Złóż darmowe zapytanie na wycenę transportu morskiego, lotniczego lub kolejowego. Zyskaj doświadczonego partnera logistycznego. Współpracuj z polską spedycją o globalnym zasięgu.',
    en: 'Make a free inquiry for a quote for sea, air or rail transport. Gain an experienced logistics partner. Cooperate with a Polish forwarding company with a global reach.',
    ua: 'Зробіть безкоштовний запит для отримання пропозиції морського, повітряного або залізничного транспорту. Отримайте досвідченого партнера з логістики. Співпрацюйте з польською експедиторською компанією з глобальним охопленням.',
  },
}

const FormSectionQuote = () => {
  const { lang } = useLangContext()
  return (
    <Main
      h={2}
      s={1}
      title={content.title[lang]}
      desc={content.desc[lang]}
      subtitle={content.subtitle[lang]}
    >
      <FormDynamic notypes override='QUOTE' />
    </Main>
  )
}

export default FormSectionQuote
