import { Link } from 'gatsby'
import { navigate } from '@reach/router'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import alt from '../content/alt'
import { useLangContext } from '../context/lang.context'
import { colors, sAbsoluteMax, s, globals, alpha } from '../style'

const LinkTile = ({ data }) => {
  const { lang } = useLangContext()
  const { subtitle, image, slug } = data

  const title = typeof data.title === 'object' ? data.title[lang] : data.title

  return (
    <div
      css={sContainer}
      onClick={() => navigate(slug)}
      onKeyPress={() => navigate(slug)}
      role='button'
      tabIndex={0}>
      <GatsbyImage
        image={getImage(image)}
        alt={title + alt}
        css={sImage}
        imgClassName='LinkTile-image'
      />
      <div css={sEffect1} />
      <div css={sEffect2} />
      <div css={sEffect3} />
      {/* <div css={sEffect4} /> */}
      <div css={sEffect5} />
      <div css={sEffect6} />
      <div css={sEffect7} className='LinkTile-hover' />
      <span css={sSubtitle}>{subtitle[lang]}</span>
      <Link css={[sTitle, title.length > 15 && sTitleSmaller]} to={slug}>
        {title}
      </Link>
    </div>
  )
}

const sContainer = {
  cursor: 'pointer',
  borderRadius: '2rem',
  position: 'relative',
  top: 0,
  left: 0,
  backgroundColor: colors.brandDarker,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  padding: '4rem',
  overflow: 'hidden',
  [s.md_down]: {
    padding: '3rem',
  },
  [s.sm_down]: {
    minHeight: 'calc(100vw / 2)',
    padding: '2rem',
  },
  [s.md]: {
    minHeight: `
      calc(
        calc(${globals.maxWidth}px) 
        / 5)
        `,
  },
  '.LinkTile-image': {
    transition: 'transform 0.2s ease-out',
  },
  '&:hover': {
    [s.hover]: {
      '.LinkTile-image': {
        transform: 'scale(1.25)',
      },
      '.LinkTile-hover': {
        opacity: 1,
      },
    },
  },
}

const sImage = {
  ...sAbsoluteMax,
  borderRadius: '2rem',
  img: {
    borderRadius: '2rem',
  },
}

const sSubtitle = {
  position: 'relative',
  top: 0,
  left: 0,
  zIndex: 10,
  textTransform: 'uppercase',
  color: colors.brandLighter,
  marginBottom: '0.5rem',
  fontWeight: 500,
  fontSize: '1rem',
  letterSpacing: '0.25em',
}

const sTitle = {
  position: 'relative',
  top: 0,
  left: 0,
  zIndex: 10,
  lineHeight: '1.2em',
  fontSize: '4rem',
  fontWeight: 700,
  color: 'white',
  letterSpacing: '-0.05em',
  [s.md_down]: {
    fontSize: '3rem',
  },
  [s.sm_down]: {
    fontSize: '2.5rem',
  },
}

const sTitleSmaller = {
  fontSize: '3rem',
  [s.md_down]: {
    fontSize: '2.5rem',
  },
  [s.sm_down]: {
    fontSize: '2rem',
  },
}

const sAbsolute = {
  ...sAbsoluteMax,
  // [s.sm_down]: {
  //   width: '200%',
  // },
}

// prettier-ignore
const sEffect1 = {
  ...sAbsolute,
  zIndex: 1,
  borderRadius: 'inherit',
  background: `linear-gradient(to top right, ${colors.brandDark}, ${colors.brandDark.concat(alpha[0])})`,
  mixBlendMode: 'overlay'
}
// prettier-ignore
const sEffect2 = {
  ...sAbsolute,
  zIndex: 2,
  borderRadius: 'inherit',
  background: `linear-gradient(to top right, ${colors.brandDark}, ${colors.brandDark.concat(alpha[0])})`,
  mixBlendMode: 'overlay',
  opacity: 0.5
}
// prettier-ignore
const sEffect3 = {
  ...sAbsolute,
  zIndex: 3,
  borderRadius: 'inherit',
  background: `linear-gradient(to right, ${colors.brandDarker}, ${colors.brand.concat(alpha[0])})`,
  mixBlendMode: 'soft-light'
}
// prettier-ignore
// const sEffect4 = {
//   ...sAbsolute,
//   zIndex: 4,
//   borderRadius: 'inherit',
//   background: `linear-gradient(to bottom,
//     ${colors.white.concat(alpha[50])},
//     ${colors.white.concat(alpha[0])},
//     ${colors.white.concat(alpha[0])})`,
//   mixBlendMode: 'lighten'
// }
// prettier-ignore
const sEffect5 = {
  ...sAbsolute,
  zIndex: 5,
  borderRadius: 'inherit',
  background: `linear-gradient(to top right, 
    ${colors.brand.concat(alpha[100])}, 
    ${colors.brand.concat(alpha[30])} 50%, 
    ${colors.brand.concat(alpha[0])} 75%, 
    ${colors.brand.concat(alpha[0])})`,
  mixBlendMode: 'color'
}
// prettier-ignore
const sEffect6 = {
  ...sAbsolute,
  zIndex: 6,
  borderRadius: 'inherit',
  background: `linear-gradient(to top right, 
    ${colors.brandBack.concat(alpha[100])}, 
    ${colors.brandBack.concat(alpha[60])} 25%, 
    ${colors.brand.concat(alpha[30])} 50%,
    ${colors.brand.concat(alpha[0])} 75%,  
    ${colors.brand.concat(alpha[0])})`,
  mixBlendMode: 'multiply'
}
// prettier-ignore
const sEffect7 = {
  ...sAbsolute,
  zIndex: 7,
  borderRadius: 'inherit',
  background: `linear-gradient(to top right, 
    ${colors.brandBack}, 
    ${colors.brandDarker} 33%,  
    ${colors.brand})`,
  mixBlendMode: 'overlay',
  opacity: 0,
  transition: 'opacity 0.2s ease-out',
  // [s.sm_down]: {
  //   opacity: 0.5
  // }
}

export default LinkTile
